@import '/src/themes/breakpoints/breakpoints.scss';

.contact-page {
    height: calc(100vh - 4rem);

    @media (max-width: $screen-xl-min) {
        height: calc(100vh - 4rem - 64px);
    }

    @media (max-width: $screen-sm-min) {
        height: 100%;
        flex-direction: column !important;
    }

    .ant-layout-sider {
        @media (max-width: $screen-sm-min) {
            max-width: 100% !important;
            width: 100% !important;
            max-height: 30% !important;
            height: 30% !important;
            overflow-y: hidden;
        }
        
        .ant-layout-sider-children > div {
            @media (max-width: $screen-sm-min) {
                max-height: 100% !important;
            }
        }
    }

    .contact-content {
        display: flex;
        flex-direction: column;
        margin: 1.25rem 0rem 1.25rem 0rem !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
        overflow-y: auto !important;

        @media (max-width: $screen-sm-min) {
            width: 100% !important;
            height: calc(100vh - 4rem - 64px - 250px) !important;
            max-height: 65% !important;
        }

        .header {
            display: flex;
            padding: 0rem 2rem;
            align-items: center;
            justify-content: space-between;
            height: 10rem !important;

            .header-name {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & > div {
                    max-height: 40px;
                    overflow-y: auto;
                    word-break: break-all;
                }
            }
        }

        .ant-card .ant-card-body {
            padding: 0.75rem 1.5rem;
        }

        .ant-divider-horizontal {
            margin: 0.5rem !important;
        }

        .add-contact-btn {
            position: absolute;
            left: 20.5rem;
        }
    }
}


.contact-event-modal {
    .ant-modal-content {

        width: 42.625rem !important;
        border-radius: 0.5rem;
        padding: 2rem 2.5rem 0rem 2.5rem;
    }

    .ant-modal-close {
        padding-top: 1.5rem;
        padding-right: 2rem;
        background: transparent;
        color: black;
    }

    .ant-modal-close:hover {
        background: transparent;
    }
}

.new-contact-modal {
    .ant-modal-content {
        width: 47rem !important;
        border-radius: 0.5rem;
        padding: 1rem 2rem 0rem 2rem;
    }

    .ant-modal-body {
        padding: 2rem 6rem 1rem 7rem;
    }

    .ant-modal-footer {
        text-align: end !important;
        padding: 0rem 6rem 4rem 7rem;
    }

    .ant-modal-close {
        padding-top: .5rem;
        padding-right: 2rem;
        background: transparent;
        color: black;
    }

    .ant-modal-close:hover {
        background: transparent;
    }
}

.import-contact-modal {
    .ant-modal-content {
        width: 35rem !important;
        border-radius: 0.5rem;
        padding: 1rem 2rem 0rem 2rem;
    }

    .ant-modal-body {
        padding: 2rem 6rem 1rem 7rem;
    }

    .ant-modal-footer {
        text-align: end !important;
        padding: 0rem 6rem 4rem 7rem;
    }

    .ant-modal-close {
        padding-top: .5rem;
        padding-right: 2rem;
        background: transparent;
        color: black;
    }

    .ant-modal-close:hover {
        background: transparent;
    }
}

.error-contact-modal {
    .ant-modal-content {
        min-width: 45rem;
        border-radius: 0.5rem;
        padding: 1rem 2rem 0rem 2rem;
    }

    .ant-modal-body {
        padding-top: 1rem;
    }

    .ant-modal-footer {
        text-align: end !important;
        padding: 0rem 6rem 4rem 7rem;
    }

    .ant-modal-close {
        padding-top: .5rem;
        padding-right: 2rem;
        background: transparent;
        color: black;
    }

    .ant-modal-close:hover {
        background: transparent;
    }
}


.ant-layout {
    border-color: #F5F5F5;
}